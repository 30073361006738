import React from "react"
import Button from "../../components/button"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
// import Button from "../components/button"
import "../../styles/homestay.css"
const CareersGatewayProgrammes = () => {
  return (
    <Layout title="Homestay" description="">
      <div class="layout-container qualification-content-container marginisleft" style={{justifyContent:"space-between"}}>
        <div class="qualification-content">
          <div class="qualification-crumbs">School PLD & Gateway / Careers & Gateway Programmes</div>
          <div className="qualification-content-part">
          <h6 style={{padding:"30px",lineHeight:"1.5",background:"#eaeff1",marginBottom:"30px",borderRadius:"15px",paddingTop:"50px"}}><span style={{display:"block",fontSize:"50px",lineHeight:"0.4"}}>❝</span>Thank you so much for coming and getting those results for our students; they (and their whānau) are all really appreciative!<br></br><br></br>– Amy, Specialist & Classroom Teacher, Cashmere High School</h6>
            <p className="p-lg" style={{marginBottom:"15px"}}>Summit Institute works with a large number of High Schools to support careers advisors and leaders. Our courses enable students to gain job-readiness with a range of in-demand credits that are appropriate for NCEA. Each school completes an MOU with Summit Institute, allowing our high-trained and experienced team to train your students in your school.</p>
            <p className="p-lg" style={{marginBottom:"15px"}}>
            At times we may take photos during learning activities. We share these with NZQA & the appropriate WDC during our moderation processes (Summit Institute has responsibility for all marking & moderation requirements); photos are never used for other purposes without permission.
            </p>
            <p className="p-lg" style={{marginBottom:"15px"}}>
            After successfully completing a course with us, each student must complete the course evaluation form. Then each school can:
            </p>
            <p className="p-lg" style={{marginBottom:"15px"}}>
            - use Summit Institute’s NZQA provider number to enter credits on each student’s ROL within 10 days,<br></br>
- inform students of their success, and<br></br>
- pay the NZQA per-credit fees for each student.
            </p>
            <p className="p-lg" style={{marginBottom:"15px"}}>
            Each MOU between Summit Institute and school is per-course; this means we create a new MOU for each course.
            </p>
            <p className="p-lg" style={{marginBottom:"30px"}}>
            For more information please contact us and we will be very happy to support your students to gain greater success and work readiness.
            </p>
            <h5>2025 Course Options</h5>
            <strong style={{marginTop:"30px",display:"block",textDecoration:"underline"}}>1-Day Course Options</strong>
            <strong style={{marginTop:"10px",display:"block"}}>Course 1 - 8 credits (Min 15 & Max 30 students per course)</strong>
            
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>525</td>
                  <td>Recognise sexual harassment and describe responses</td>
                  <td>2</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>542</td>
                  <td>Recognise discrimination and describe ways of responding</td>
                  <td>2</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>377</td>
                  <td>Demonstrate knowledge of diversity in workplaces</td>
                  <td>2</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 2 - 7 Credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17594</td>
                  <td>Demonstrate knowledge of hearing conservation in the workplace</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or alcohol-related problems in the workplace </td>
                  <td>3</td>
                  <td>4</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 3 - 8 Credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17591</td>
                  <td>Demonstrate knowledge of the prevention and management of Discomfort, Pain, and Injury (DPI) in the workplace</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 4 - 7 credits (Max 14 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>23229</td>
                  <td>Use safety harness system when working at height</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17600</td>
                  <td>Explain safe work practices for working at heights</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 5 - 6 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30266</td>
                  <td>Demonstrate knowledge of workplace health and safety culture and practices</td>
                  <td>3</td>
                  <td>6</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 6 - 7 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or alcohol-related problems in the workplace </td>
                  <td>3</td>
                  <td>4</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 7 - 7 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17459</td>
                  <td>Demonstrate and apply knowledge of safe manual handling practices in the workplace </td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 8 - 7 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>26971</td>
                  <td>Describe factors that contribute to mental health wellbeing and mental health challenges </td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or alcohol-related problems in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 9 - 7 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>33142</td>
                  <td>Demonstrate knowledge of alcohol and other drugs for wellbeing/hauora</td>
                  <td>2</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or alcohol-related problems in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 10 - 8 credits (Max 14 students per course)
            </strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>23960</td>
                  <td>Assess the worksite, prepare and operate a scissor lift
                  elevating work platform (EWP) </td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>23966</td>
                  <td>Describe types of elevating work platforms (EWPs), and
                  industry requirements for their use</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>19522</td>
                  <td>Undertake job safety analysis</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>23966 to be completed as pre-course work using a google form</strong>
            <div class="mainbtns" style={{marginTop:"20px"}}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
            <strong style={{marginTop:"30px",display:"block",textDecoration:"underline"}}>2-Day Course Options</strong>
            <strong style={{marginTop:"10px",display:"block"}}>Course 11 - 15 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17591</td>
                  <td>Demonstrate knowledge of the prevention and
management of Discomfort, Pain, and Injury (DPI) in the
workplace</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent
                  injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17459</td>
                  <td>Demonstrate and apply knowledge of safe manual handling
                  practices in the workplace</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 12 - 16 credits (Min 15 & Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30265</td>
                  <td>Apply health and safety risk assessment to a job role</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent
                  injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or
                  alcohol-related problems in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 13 - 13 credits (Max 14 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30265</td>
                  <td>Apply health and safety risk assessment to a job role</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>23960</td>
                  <td>Assess the worksite, prepare and operate a scissor lift
                  elevating work platform (EWP)</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>23966</td>
                  <td>Describe types of elevating work platforms (EWPs), and
                  industry requirements for their use</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 14 - 15 credits (Max 14 students per course)
            </strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30265</td>
                  <td>Apply health and safety risk assessment to a job role</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>23229</td>
                  <td>Use safety harness system when working at height</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17600</td>
                  <td>Explain safe work practices for working at heights</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 15 - 14 credits (Min 15 and Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent
                  injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>22316</td>
                  <td>Demonstrate knowledge of the management of drug and/or
                  alcohol-related problems in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>18408</td>
                  <td>Demonstrate knowledge of fire and emergency warden
                  duties in the workplace</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>17594</td>
                  <td>Demonstrate knowledge of hearing conservation in the
                  workplace</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 16 - 15 credits (Min 15 and Max 30 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30265</td>
                  <td>Apply health and safety risk assessment to a job role</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>17592</td>
                  <td>Identify the causes of back injury and methods to prevent
                  injuries in the workplace</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>17594</td>
                  <td>Demonstrate knowledge of hearing conservation in the
                  workplace</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <strong style={{marginTop:"30px",display:"block"}}>Course 17 - 15 credits (Min 15 and Max 20 students per course)</strong>
            <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30265</td>
                  <td>Apply health and safety risk assessment to a job role
                  (flight delivery coordinator)</td>
                  <td>3</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>9695</td>
                  <td>Examine problem-solving models and apply a problemsolving model to a problem of some complexity (drone delivery scenarios)</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>27557</td>
                  <td>Behave according to organisational requirements (focus is a drone delivery service)</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <div class="mainbtns" style={{marginTop:"20px"}}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
            <div className="termsconditions" style={{marginTop:"40px"}}>
            <h5>Terms and Conditions of Service</h5>
            <ul className="p-lg" style={{marginBottom:"15px"}}>
              <li style={{marginBottom:"10px"}}>Ensure that the all students complete a post course evaluation within 24 hours of course completion (prior to entering student ROL credits) using the online form at <a href="http://bit.ly/summitstudenteval" target="_blank">bit.ly/summitstudenteval</a> or via the following QR code; note that this is an NZQA requirement for all Summit Institute courses.</li>
              <li style={{marginBottom:"10px"}}>Enter the results for the successful students within ten working days of Summit Institute providing the school with the names of students who have achieved the unit standards & pay the NZQA fees for loading credits on their record of learning.</li>
              <li style={{marginBottom:"10px"}}>Permit Summit Institute to take photos and/or videos during courses for the Work Force Development Council and/or NZQA moderation purposes (note, this is not for promotion or public sharing).</li>
            </ul>
            <p className="p-lg" style={{marginBottom:"15px"}}>All work will be carried out with all care and due diligence by qualified, authorised and certified trainers from Summit Institute. Summit Institute and the relevant Workforce Development Council and/or NZQA will manage all internal and external moderation. Should a resolution of differences arise, student outcomes will remain the focus of discussions; both parties agree to meet with an approved mediator from the New Zealand Dispute Resolution Centre. Should this MOU be cancelled, a termination will come into affect after all students related to this MOU complete their agreed course of study and they have received their appropriate credits.</p>
            <p className="p-lg" style={{marginBottom:"15px"}}>Cancellation or modification to enrolment is accepted without penalty up to ten (10) working days prior to course commencement, after which payment must be made in full. This agreement is based on a minimum 15 and maximum 30 learners attending the course and payments are to be made accordingly. The purchaser acknowledges that the services of the kind referred to in this agreement are for business use and that the purchaser (school) agrees that the Consumer Guarantees Act 1993 will not apply in respect of this agreement. The invoice must be paid by the 20Th of the month post course date. Overdue accounts(s) may be charged interest of 3% per month that the account(s) is overdue invoice.</p>
            <p className="p-lg" style={{marginBottom:"15px"}}>This MOU will be terminated once The Summit Institute returns the result record, and the invoice is paid in full. Results should be received within ten working days of the course providing the school with the names of students who have achieved the unit standard(s).</p>
            </div>
          </div>
          <div class="only-mobile">
            <div class="qualification-info-container">
            <h6 style={{marginBottom: "22px"}}>Currently we are excited to be collaborating with the following schools:</h6>
            <div class="qualification-info-item"><div class="p-md">Amuri Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Aorere College </div></div>
              <div class="qualification-info-item"><div class="p-md">Aurora College</div></div>
              <div class="qualification-info-item"><div class="p-md">Bay of Island College</div></div>
              <div class="qualification-info-item"><div class="p-md">Birkenhead College</div></div>
              <div class="qualification-info-item"><div class="p-md">Bream Bay College </div></div>
              <div class="qualification-info-item"><div class="p-md">Cashmere High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Catholic Cathedral College</div></div>
              <div class="qualification-info-item"><div class="p-md">Central Hawkes Bay College</div></div>
              <div class="qualification-info-item"><div class="p-md">Chanel College</div></div>
              <div class="qualification-info-item"><div class="p-md">Dargaville High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Forest View High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Glenfield College</div></div>
              <div class="qualification-info-item"><div class="p-md">Henderson High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Heretaunga College</div></div>
              <div class="qualification-info-item"><div class="p-md">Hillmorton High School </div></div>
              <div class="qualification-info-item"><div class="p-md">Huntly College </div></div>
              <div class="qualification-info-item"><div class="p-md">Hurunui High School</div></div>
              <div class="qualification-info-item"><div class="p-md">James Cook High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Kaiapoi College</div></div>
              <div class="qualification-info-item"><div class="p-md">Karamu High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Lincoln High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Mangere College</div></div>
              <div class="qualification-info-item"><div class="p-md">Manurewa High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Matamata College</div></div>
              <div class="qualification-info-item"><div class="p-md">Mount Maunganui College</div></div>
              <div class="qualification-info-item"><div class="p-md">Napier Girls High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Northcote College</div></div>
              <div class="qualification-info-item"><div class="p-md">Onewhero Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Ormiston College</div></div>
              <div class="qualification-info-item"><div class="p-md">Ormiston Senior College</div></div>
              <div class="qualification-info-item"><div class="p-md">Oxford Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Papanui High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Papatoetoe High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Pukekohe High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Reporoa College</div></div>
              <div class="qualification-info-item"><div class="p-md">Riccarton High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Rotorua Girls High</div></div>
              <div class="qualification-info-item"><div class="p-md">Sacred Heart College Napier</div></div>
              <div class="qualification-info-item"><div class="p-md">St Andrews College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Bedes College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Bernards College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Dominics College </div></div>
              <div class="qualification-info-item"><div class="p-md">St Johns College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Thomas of Canterbury</div></div>
              <div class="qualification-info-item"><div class="p-md">Takapuna Grammar School</div></div>
              <div class="qualification-info-item"><div class="p-md">Taradale High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Tararua College</div></div>
              <div class="qualification-info-item"><div class="p-md">Te Aroha College</div></div>
              <div class="qualification-info-item"><div class="p-md">Tokomairiro College</div></div>
              <div class="qualification-info-item"><div class="p-md">Waihake High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Waiopehu College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wairoa College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wellington East Girls College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wellington High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Wesley College</div></div>
              <div class="qualification-info-item"><div class="p-md">Western Heights High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Woodford House</div></div>
            </div>
          </div>
        </div>
        <div class="hide-mobile">
          <div class="qualification-info-container">
            <h6 style={{marginBottom: "22px"}}>Currently we are excited to be collaborating with the following schools:</h6>
            <div class="qualification-info-item"><div class="p-md">Amuri Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Aorere College </div></div>
              <div class="qualification-info-item"><div class="p-md">Aurora College</div></div>
              <div class="qualification-info-item"><div class="p-md">Bay of Island College</div></div>
              <div class="qualification-info-item"><div class="p-md">Birkenhead College</div></div>
              <div class="qualification-info-item"><div class="p-md">Bream Bay College </div></div>
              <div class="qualification-info-item"><div class="p-md">Cashmere High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Catholic Cathedral College</div></div>
              <div class="qualification-info-item"><div class="p-md">Central Hawkes Bay College</div></div>
              <div class="qualification-info-item"><div class="p-md">Chanel College</div></div>
              <div class="qualification-info-item"><div class="p-md">Dargaville High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Forest View High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Glenfield College</div></div>
              <div class="qualification-info-item"><div class="p-md">Henderson High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Heretaunga College</div></div>
              <div class="qualification-info-item"><div class="p-md">Hillmorton High School </div></div>
              <div class="qualification-info-item"><div class="p-md">Huntly College </div></div>
              <div class="qualification-info-item"><div class="p-md">Hurunui High School</div></div>
              <div class="qualification-info-item"><div class="p-md">James Cook High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Kaiapoi College</div></div>
              <div class="qualification-info-item"><div class="p-md">Karamu High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Lincoln High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Mangere College</div></div>
              <div class="qualification-info-item"><div class="p-md">Manurewa High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Matamata College</div></div>
              <div class="qualification-info-item"><div class="p-md">Mount Maunganui College</div></div>
              <div class="qualification-info-item"><div class="p-md">Napier Girls High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Northcote College</div></div>
              <div class="qualification-info-item"><div class="p-md">Onewhero Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Ormiston College</div></div>
              <div class="qualification-info-item"><div class="p-md">Ormiston Senior College</div></div>
              <div class="qualification-info-item"><div class="p-md">Oxford Area School</div></div>
              <div class="qualification-info-item"><div class="p-md">Papanui High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Papatoetoe High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Pukekohe High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Reporoa College</div></div>
              <div class="qualification-info-item"><div class="p-md">Riccarton High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Rotorua Girls High</div></div>
              <div class="qualification-info-item"><div class="p-md">Sacred Heart College Napier</div></div>
              <div class="qualification-info-item"><div class="p-md">St Andrews College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Bedes College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Bernards College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Dominics College </div></div>
              <div class="qualification-info-item"><div class="p-md">St Johns College</div></div>
              <div class="qualification-info-item"><div class="p-md">St Thomas of Canterbury</div></div>
              <div class="qualification-info-item"><div class="p-md">Takapuna Grammar School</div></div>
              <div class="qualification-info-item"><div class="p-md">Taradale High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Tararua College</div></div>
              <div class="qualification-info-item"><div class="p-md">Te Aroha College</div></div>
              <div class="qualification-info-item"><div class="p-md">Tokomairiro College</div></div>
              <div class="qualification-info-item"><div class="p-md">Waihake High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Waiopehu College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wairoa College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wellington East Girls College</div></div>
              <div class="qualification-info-item"><div class="p-md">Wellington High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Wesley College</div></div>
              <div class="qualification-info-item"><div class="p-md">Western Heights High School</div></div>
              <div class="qualification-info-item"><div class="p-md">Woodford House</div></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CareersGatewayProgrammes
